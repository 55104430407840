import { BigNumber, Contract, utils, constants } from 'ethers';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion';

import { useStreamContext } from '../context/StreamContext';
import AddressSearch from '../components/AddressSearch';
import EditName from '../components/EditName';
import StreamGranularity from '../components/StreamGranularity';
import Metadata from '../components/Metadata';
import { LoadMore } from '../components/LoadMore';
import FormattedNumber from '../components/FormattedNumber';
import SuperLoader from '../components/SuperLoader';
import {
  Table,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderData,
  TableData,
} from '../components/Table';
import { EntityType, ChainType } from '../hooks/useAddressSearch';
import {
  buildAddressKey,
  storeAddress,
  removeAddress,
  getAddress,
} from '../lib/localStorage';
import {
  fetchTokenFlows,
  TokenStream,
  fetchTokenById,
  Token,
} from '../graphql';
import { getNetworkByName, getProviderByChainName } from '../lib/networks';
import { getShortenAddress, getLastElement } from '../lib/addressHelpers';

import Star from '../components/Star';
import '@reach/accordion/styles.css';
import { ZeroAddresses } from '../constants';
import _ from 'lodash';
import { getErc20TokenContractMemoized } from '../utils/erc20';

type SupertokenParams = {
  address: string;
  chainName: string;
};

const GridBody = styled.div`
  grid-area: body;
  display: grid;
  grid-template:
    'title search' 6rem
    'metadata metadata' auto
    'flows flows' 2fr
    / 1fr 1fr;
`;

const GridSearch = styled(AddressSearch)`
  grid-area: search;
  justify-self: end;
  cursor: pointer;
  justify-content: flex-end;
`;
const GridFlows = styled.div`
  grid-area: flows;
  margin: 1rem 0 0 2rem;
`;

const TableStyled = styled(Table)`
  width: 100%;
`;

const AccordionButtonStyled = styled(AccordionButton)`
  width: 100%;
  height: 100%;
  background-color: var(--secondary-bg-color);
  border: none;
  cursor: pointer;

  :hover {
    background-color: var(--secondary-hover-color);
  }
`;

const TableRowStyled = styled(TableRow)`
  :hover {
    background-color: inherit;
    cursor: inherit;
  }
`;

const AccordionButtonContainer = styled.div`
  height: 2rem;
`;

const MetadataContainer = styled.div`
  grid-area: metadata;
  margin-left: 2rem;
  display: flex;
`;

const LeftMetadataContainer = styled.div`
  margin-right: 2rem;
`;

const PageTitle = styled.h1`
  grid-area: title;
  margin-left: 2rem;
`;

const SuperTokenPage: React.FC = () => {
  const [tokenName, setTokenName] = useState('');
  const { flowRateGranularity } = useStreamContext();
  const [page, setPage] = useState(1);
  const [token, setToken] = useState<Token | null>(null);
  const [numberOfStreams, setNumberOfStreams] = useState(0);
  const [totalSuperTokenSupply, setTotalSuperTokenSupply] = useState('');
  const [totalUnderlyingTokenSupply, setTotalUnderlyingTokenSupply] = useState<
    string | undefined
  >(undefined);
  const [lastId, setLastId] = useState('');
  const [exists, setExists] = useState(false);
  const [flows, setFlows] = useState<TokenStream[]>([]);
  const { address, chainName } = useParams<SupertokenParams>();

  const [loadingFlows, setLoadingFlows] = useState(false);
  const [newTokenFlowCount, setNewTokenFlowCount] = useState(0);

  const handleFavoriteClick = () => {
    if (!token) {
      return;
    }
    if (exists) {
      removeAddress(token.id, chainName as ChainType);
    } else {
      storeAddress({
        name: getShortenAddress(token.id),
        address: token.id,
        type: 'Token' as EntityType,
        chain: chainName as ChainType,
        id: token.id,
      });
    }
    setExists(!exists);
  };

  const getFlows = useCallback(
    async (gt = true) => {
      const pageSize = 20;
      const chainId = getNetworkByName(chainName);
      if (chainId) {
        setLoadingFlows(true);
        const newTokenFlows = await fetchTokenFlows(
          address,
          chainId.id,
          lastId,
          gt,
          pageSize
        );
        setLoadingFlows(false);
        if (newTokenFlows.length <= 0) {
          return;
        }
        setFlows([...flows, ...newTokenFlows]);
        setNewTokenFlowCount(newTokenFlows.length);
        const el = getLastElement(newTokenFlows);
        if (el) {
          setLastId(el.id);
        }
      }
    },
    [address, chainName, lastId, flows]
  );

  const handlePageForward = () => {
    setPage(page + 1);
    getFlows();
  };

  useEffect(() => {
    if (token) {
      const addressMetadata = getAddress(`${token.id}-${chainName}`);
      if (addressMetadata) {
        setTokenName(addressMetadata.name);
      } else {
        setTokenName(getShortenAddress(token.id));
      }
    }
  }, [token, chainName]);

  useEffect(() => {
    const exists = getAddress(`${address}-${chainName}`);
    if (exists) {
      setExists(true);
    } else {
      setExists(false);
    }
  }, [address, chainName]);

  useEffect(() => {
    getFlows();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getTokenInfo = async () => {
      const chainId = getNetworkByName(chainName);
      if (chainId) {
        const token = await fetchTokenById(address, chainId.id);
        if (token) {
          if (token.streams) {
            setNumberOfStreams(token.streams.length);
          }
          setToken(token);
        }
      }
    };
    getTokenInfo();
  }, [address, chainName]);

  useEffect(() => {
    const fetchSupply = async () => {
      if (token) {
        const abi = ['function totalSupply() view returns (uint256)'];
        const provider = await getProviderByChainName(chainName);

        const superTokenContract = await getErc20TokenContractMemoized(
          chainName,
          token.id
        );
        const totalSuperTokenSupply = await superTokenContract.totalSupply();
        setTotalSuperTokenSupply(totalSuperTokenSupply.toLocaleString());

        const hasUnderlyingToken = !(token.underlyingAddress in ZeroAddresses);
        if (hasUnderlyingToken) {
          const underlyingTokenContract = new Contract(
            token.underlyingAddress,
            abi,
            provider
          );
          setTotalUnderlyingTokenSupply(
            await underlyingTokenContract
              .totalSupply()
              .then((x: any) => x.toLocaleString())
          );
        }
      }
    };
    fetchSupply();
  }, [token, chainName]);
  return (
    <GridBody>
      <PageTitle data-cy={'supertoken-name'}>
        <span onClick={handleFavoriteClick} style={{ marginRight: '1rem' }}>
          <Star filled={exists} />
        </span>
        Supertoken {tokenName}
      </PageTitle>
      <GridSearch></GridSearch>
      <MetadataContainer>
        <LeftMetadataContainer>
          <Metadata header="Network" metadata={_.capitalize(chainName)} />
          <Metadata header="Address" metadata={token ? token.id : ''} />
          <Metadata header="Name" metadata={token ? token.name : ''} />
          <Metadata header="Symbol" metadata={token ? token.symbol : ''} />
          <Metadata
            header="Token Type"
            metadata={
              token && token.underlyingAddress === constants.AddressZero
                ? 'Custom'
                : 'Wrapped'
            }
          />
        </LeftMetadataContainer>
        <div>
          <Metadata header="Total Supply" metadata={totalSuperTokenSupply} />
          <Metadata
            header="Underlying Token Address"
            metadata={token ? token.underlyingAddress : ''}
          />
          {totalUnderlyingTokenSupply && (
            <Metadata
              header="Underlying Token Supply"
              metadata={totalUnderlyingTokenSupply}
            />
          )}
          <Metadata header="Latest Supertoken.sol" metadata="Yes" />
          <Metadata
            header="Number of Open Streams"
            metadata={
              numberOfStreams === 1000
                ? `${numberOfStreams}+`
                : `${numberOfStreams}`
            }
          />
        </div>
      </MetadataContainer>
      <GridFlows>
        <StreamGranularity />
        <Accordion multiple collapsible defaultIndex={0}>
          <AccordionItem>
            <AccordionButtonContainer>
              <AccordionButtonStyled>Streams</AccordionButtonStyled>
            </AccordionButtonContainer>
            <AccordionPanel>
              <TableStyled>
                <TableHeader>
                  <TableRowStyled>
                    <TableHeaderData>Sender</TableHeaderData>
                    <TableHeaderData>Receiver</TableHeaderData>
                    <TableHeaderData>Rate</TableHeaderData>
                  </TableRowStyled>
                </TableHeader>
                <TableBody>
                  {flows.map((flow) => (
                    <TableRowStyled key={flow.id}>
                      <TableData>
                        <EditName
                          address={
                            getAddress(
                              buildAddressKey(
                                flow.sender.id,
                                chainName as ChainType
                              )
                            ) || {
                              name: getShortenAddress(flow.sender.id),
                              address: flow.sender.id,
                              type: 'Account' as EntityType,
                              chain: chainName as ChainType,
                              id: flow.sender.id,
                            }
                          }
                        />
                      </TableData>
                      <TableData>
                        <EditName
                          address={
                            getAddress(
                              buildAddressKey(
                                flow.receiver.id,
                                chainName as ChainType
                              )
                            ) || {
                              name: getShortenAddress(flow.receiver.id),
                              address: flow.receiver.id,
                              type: 'Account' as EntityType,
                              chain: chainName as ChainType,
                              id: flow.receiver.id,
                            }
                          }
                        />
                      </TableData>
                      <TableData>
                        <FormattedNumber>
                          {utils.formatEther(
                            BigNumber.from(flow.currentFlowRate).mul(
                              BigNumber.from(flowRateGranularity)
                            )
                          )}
                        </FormattedNumber>
                      </TableData>
                    </TableRowStyled>
                  ))}
                  {loadingFlows ? (
                    <tr>
                      <td colSpan={5}>
                        <SuperLoader />
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  <TableRowStyled>
                    <TableData colSpan={5}>
                      <LoadMore
                        disabled={newTokenFlowCount < 20}
                        handleLoadMore={handlePageForward}
                      />
                    </TableData>
                  </TableRowStyled>
                </TableBody>
              </TableStyled>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </GridFlows>
    </GridBody>
  );
};

export default SuperTokenPage;
