import React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOM from 'react-dom';

// Create root level element for react-tooltips
const domNode = document.createElement('div');
document.body.appendChild(domNode);

// Wrapper component to portal react-tooltips
const BodyPortal: React.FC = ({ children }) => {
  return ReactDOM.createPortal(children, domNode);
};

// Custom tooltip wrapper to ensure all tooltips get rendered into the portal
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Tooltip = (props: any) => {
  return (
    <BodyPortal>
      <ReactTooltip {...props} />
    </BodyPortal>
  );
};

export default Tooltip;
