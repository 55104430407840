import React, { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { utils, BigNumber } from 'ethers';
import { TokenStream, ModifiedFlowUpdatedEvent } from '../graphql';
import { Tr, Td, Bold } from '../components/CurrencyTable';
import FormattedNumber from '../components/FormattedNumber';
import FormattedDate from '../components/FormattedDate';

import { getAddressName } from '../lib/addressHelpers';
import { useStreamContext } from '../context/StreamContext';
import { ChainType } from '../hooks/useAddressSearch';
import up from '../assets/up.svg';
import down from '../assets/down.svg';

type Props = {
  index: number;
  flow: TokenStream;
  event: ModifiedFlowUpdatedEvent;
  blocktime: number;
};

type AccountParams = {
  address: string;
  chainName: string;
};

const EventRow: React.FC<Props> = ({ index, flow, event, blocktime }) => {
  const { address, chainName } = useParams<AccountParams>();
  const { flowRateGranularity } = useStreamContext();
  const [flowRate, setFlowRate] = useState('0');
  const getRate = (
    oldRate: string,
    newRate: string,
    flowGranularity: number
  ) => {
    if (oldRate === '0') {
      return BigNumber.from(newRate)
        .mul(BigNumber.from(flowGranularity))
        .toString();
    }
    if (newRate === '0') {
      return BigNumber.from(oldRate)
        .mul(BigNumber.from(flowGranularity))
        .toString();
    }
    return BigNumber.from(newRate)
      .mul(BigNumber.from(flowGranularity))
      .toString();
  };

  const calculateStreamPeriodFlowedAmount = (
    event: ModifiedFlowUpdatedEvent
  ): string => {
    if (event.endDate === 0) {
      return BigNumber.from(event.flowRate)
        .mul(blocktime - event.timestamp)
        .add(BigNumber.from(event.totalAmountStreamedUntilTimestamp))
        .toString();
    }
    return event.totalAmountStreamedUntilTimestampForNextEvent.toString();
  };

  useEffect(() => {
    const formattedFlowRate = utils.formatEther(
      getRate(event.oldFlowRate, event.flowRate, flowRateGranularity)
    );
    setFlowRate(formattedFlowRate);
  }, [flowRateGranularity, event.oldFlowRate, event.flowRate]);

  return (
    <Tr className="child">
      <Td>
        {index == 0 &&
          (flow.receiver.id !== address.toLowerCase() ? (
            <span>
              <img src={down} />
              <Bold>To:</Bold>
              <NavLink
                data-cy="event-receiver-address"
                to={`${flow.receiver.id}`}
              >
                {getAddressName(flow.receiver.id, chainName as ChainType)}
              </NavLink>
            </span>
          ) : (
            <span>
              <img src={up} />
              <Bold>From:</Bold>
              <NavLink data-cy="event-sender-address" to={`${flow.sender.id}`}>
                {getAddressName(flow.sender.id, chainName as ChainType)}
              </NavLink>
            </span>
          ))}
      </Td>
      <Td data-cy={'event-flow-rate'}>
        {flow.receiver.id !== address.toLowerCase() ? '--' : '+'}
        <FormattedNumber>{flowRate}</FormattedNumber>
      </Td>
      <Td data-cy={'event-streamed-amount'}>
        {flow.receiver.id !== address.toLowerCase() ? '--' : '+'}
        <FormattedNumber>
          {utils.formatEther(calculateStreamPeriodFlowedAmount(event))}
        </FormattedNumber>
      </Td>
      <Td data-cy={'event-time-active'} colSpan={3}>
        <FormattedDate unixTimestamp={event.startDate} />
        <span> to </span>
        {event.endDate ? (
          <FormattedDate unixTimestamp={event.endDate} />
        ) : (
          'Now'
        )}
      </Td>
      <Td></Td>
    </Tr>
  );
};

export default EventRow;
