import React, { useCallback, useEffect, useState } from 'react';

import Stream from './Stream';
import SuperLoader from '../components/SuperLoader';
import { Tr, Td } from './CurrencyTable';
import { LoadMore } from './LoadMore';
import { STREAMS_PAGE_LENGTH } from '../constants';
import { fetchAccountWithStreams, Account, TokenStream } from '../graphql';

type Props = {
  address: string;
  chainId: string;
  tokenAddress: string;
  blocktime: number;
};

const StreamTable: React.FC<Props> = ({
  address,
  chainId,
  tokenAddress,
  blocktime,
}) => {
  const [streams, setStreams] = useState<TokenStream[]>([]);
  const [showInactive, setShowInactive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableLoadMore, setDisableLoadMore] = useState(false);

  const currentTime = Math.round(new Date().getTime() / 1000).toString();
  const [ownedLastCreatedAt, setOwnedLastCreatedAt] = useState(currentTime);
  const [receivedLastCreatedAt, setReceivedLastCreatedAt] =
    useState(currentTime);

  const fetchStream = useCallback(
    async (
      gt: boolean,
      pageSize: number,
      showInactiveOverride = false
    ): Promise<[TokenStream[], Account] | null> => {
      const showInactiveStreams = showInactiveOverride || showInactive;
      const account = await fetchAccountWithStreams(
        address,
        chainId,
        tokenAddress,
        ownedLastCreatedAt,
        receivedLastCreatedAt,
        !showInactiveStreams,
        showInactiveStreams,
        gt,
        pageSize
      );

      if (!account) {
        return null;
      }

      let streams = [
        ...(account.streamsOwned || []),
        ...(account.streamsReceived || []),
      ].sort((a, b) => {
        return b.createdAtTimestamp - a.createdAtTimestamp;
      });
      if (streams.length > pageSize) {
        streams = streams.slice(0, pageSize);
      }
      return [streams, account];
    },
    [
      showInactive,
      address,
      chainId,
      ownedLastCreatedAt,
      receivedLastCreatedAt,
      tokenAddress,
    ]
  );

  const fetchStreamState = useCallback(
    async (
      gt: boolean,
      pageSize: number,
      showInactiveOverride = false
    ): Promise<
      | [{ receivedLastCreatedAt: string; ownedLastCreatedAt: string }, number]
      | null
    > => {
      const showInactiveStreams = showInactiveOverride || showInactive;
      setLoading(true);

      const resp = await fetchStream(gt, pageSize, showInactiveStreams);
      if (resp === null) {
        return null;
      }

      const [newStreams, account] = resp;
      setStreams([...streams, ...newStreams]);

      const lastCreatedAtValues = newStreams.reduce(
        (lastCreatedAtValues, tokenFlow) => {
          if (tokenFlow.sender.id === account.id) {
            return {
              receivedLastCreatedAt: lastCreatedAtValues.receivedLastCreatedAt,
              ownedLastCreatedAt: tokenFlow.createdAtTimestamp.toString(),
            };
          }
          if (tokenFlow.receiver.id === account.id) {
            return {
              receivedLastCreatedAt: tokenFlow.createdAtTimestamp.toString(),
              ownedLastCreatedAt: lastCreatedAtValues.ownedLastCreatedAt,
            };
          }
          return lastCreatedAtValues;
        },
        { receivedLastCreatedAt, ownedLastCreatedAt }
      );

      setLoading(false);
      return [lastCreatedAtValues, newStreams.length];
    },
    [
      fetchStream,
      streams,
      ownedLastCreatedAt,
      receivedLastCreatedAt,
      showInactive,
    ]
  );

  const populateStreams = useCallback(
    async (gt = true) => {
      if (!chainId) {
        return;
      }

      const receivedStreams = await fetchStreamState(gt, STREAMS_PAGE_LENGTH);
      if (!receivedStreams) {
        return;
      }
      const [lastValues, newStreamCount] = receivedStreams;

      setOwnedLastCreatedAt(lastValues.ownedLastCreatedAt.toString());
      setReceivedLastCreatedAt(lastValues.receivedLastCreatedAt.toString());

      if (newStreamCount < STREAMS_PAGE_LENGTH) {
        // Start to query inactive flows.
        if (!showInactive) {
          setReceivedLastCreatedAt(currentTime);
          setOwnedLastCreatedAt(currentTime);
          setShowInactive(true);
        } else {
          // If new stream count is less than page length then that means there are no more pages. (Imperfect solution because gives false information when exactly page length worth of new stream counts.)
          setDisableLoadMore(true);
        }
      }
    },
    [chainId, setShowInactive, currentTime, fetchStreamState, showInactive]
  );

  const handlePageForward = () => {
    populateStreams();
  };

  useEffect(() => {
    populateStreams();
  }, [showInactive]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {streams.map((stream) => (
        <Stream
          stream={stream}
          chainId={chainId}
          key={stream.id}
          blocktime={blocktime}
        />
      ))}
      {loading ? (
        <tr>
          <td colSpan={7}>
            <SuperLoader />
          </td>
        </tr>
      ) : (
        <></>
      )}
      <Tr key="row">
        <Td colSpan={7}>
          <LoadMore
            disabled={disableLoadMore}
            handleLoadMore={handlePageForward}
          />
        </Td>
      </Tr>
    </>
  );
};

export default StreamTable;
