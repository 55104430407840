import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import SuperfluidLogo from '../components/SuperfluidLogo';
import SidebarItem from '../components/SidebarItem';

import addressBook from '../assets/addressBook.svg';
import addressBookInactive from '../assets/addressBookInactive.svg';

import history from '../assets/history.svg';
import historyInactive from '../assets/historyInactive.svg';

import query from '../assets/query.svg';
import queryInactive from '../assets/queryInactive.svg';

import { ADDRESSES_PATH } from '../constants';

const GridSidebar = styled.div`
  grid-area: sidebar;
  box-shadow: var(--tertiary-bg-color) 0px 0px 0px 1px inset;
  position: fixed;
  height: 100%;
  width: 6rem;
`;

const SidebarImg = styled.img`
  height: 24px;
`;

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [historyIcon, setHistoryIcon] = useState(historyInactive);
  const [addressIcon, setAddressIcon] = useState(addressBookInactive);
  const [queryIcon, setQueryIcon] = useState(queryInactive);

  useEffect(() => {
    if (location.pathname === ADDRESSES_PATH) {
      setAddressIcon(addressBook);
    } else {
      setAddressIcon(addressBookInactive);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === '/history') {
      setHistoryIcon(history);
    } else {
      setHistoryIcon(historyInactive);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === '/subgraph') {
      setQueryIcon(query);
    } else {
      setQueryIcon(queryInactive);
    }
  }, [location.pathname]);

  return (
    <GridSidebar data-cy={'sidebar'}>
      <SidebarItem to="/">
        <SuperfluidLogo />
      </SidebarItem>
      <SidebarItem to={ADDRESSES_PATH}>
        <img src={addressIcon} alt="Address book link" />
      </SidebarItem>
      <SidebarItem to={'/history'}>
        <SidebarImg src={historyIcon} alt="search history link" />
      </SidebarItem>
      <SidebarItem to={'/subgraph'}>
        <SidebarImg src={queryIcon} alt="Run custom subgraph query" />
      </SidebarItem>
    </GridSidebar>
  );
};

export default Sidebar;
