import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Tooltip from '../components/Tooltip';

const NavLinkStyled = styled(NavLink)`
  display: flex;
  justify-content: center;
  padding: 1.2rem;
`;

type Props = {
  to: string;
};

const SidebarItem: React.FC<Props> = ({ children, to }) => {
  const name = to.split('/')[1];
  return (
    <div data-cy={'sidebar-buttons'}>
      <NavLinkStyled data-tip data-for={name} to={to}>
        {children}
      </NavLinkStyled>
      <Tooltip id="addresses" place="right" effect="solid">
        <span>Address Book</span>
      </Tooltip>
      <Tooltip id="history" place="right" effect="solid">
        <span>History</span>
      </Tooltip>
      <Tooltip id="subgraph" place="right" effect="solid">
        <span style={{ zIndex: 10 }}>Subgraph</span>
      </Tooltip>
    </div>
  );
};

export default SidebarItem;
