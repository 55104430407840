import React from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import Sidebar from './Sidebar';

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  gap: 0rem 0rem;
  display: grid;
  grid-template:
    'sidebar header _' 6rem
    'sidebar body _' 1fr
    'sidebar foot _' 5rem
    / 6rem 1fr 2rem;
`;

export const LayoutBody = styled.div`
  grid-area: body;
`;

export const MainLayout: React.FC = ({ children }) => {
  return (
    <Layout>
      <Sidebar />
      {children}
      <Footer />
    </Layout>
  );
};
