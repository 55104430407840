import { AnyAction } from '@reduxjs/toolkit';

const CONNECT_SIGNER_AND_PROVIDER = 'CONNECT_SIGNER_AND_PROVIDER';
const ACCOUNT_CHANGED = 'ACCOUNT_CHANGED';

type AppState = {
  provider?: any;
  signer?: any;
  userAddress?: string;
};

const initialState = {
  provider: undefined,
  signer: null,
  userAddress: undefined,
};

export default (state = initialState, action: AnyAction): AppState => {
  switch (action.type) {
    case CONNECT_SIGNER_AND_PROVIDER: {
      const { provider, userAddress, signer } = action.payload;
      return {
        ...state,
        userAddress,
        provider,
        signer,
      };
    }
    case ACCOUNT_CHANGED: {
      const { userAddress } = action.payload;
      return {
        ...state,
        userAddress,
      };
    }
    default:
      return state;
  }
};
