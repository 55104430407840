import { request, gql } from 'graphql-request';

import { getNetworkByChainId } from '../lib/networks';
import _ from 'lodash';
import DevConsoleLog from '../utils/DevConsoleLog';

export type AccountWithToken = {
  id: string;
  balanceUntilUpdatedAt: string;
  token: Token;
};

export type Account = {
  id: string;
  accountWithToken?: AccountWithToken[];
  streamsOwned?: TokenStream[];
  streamsReceived?: TokenStream[];
  events?: FlowUpdatedEvent[];
};

export type FlowUpdatedEvent = {
  id: string;
  oldFlowRate: string;
  flowRate: string;
  timestamp: number;
  totalAmountStreamedUntilTimestamp: number;
};

export type SubscriptionUnitsUpdatedEvent = {
  id: string;
  units: string;
  timestamp: number;
};

export type Index = {
  id: string;
  indexValue: number;
  totalUnits: number;
  totalAmountDistributedUntilUpdatedAt: string;
  token: Token;
  publisher: Account;
};

export type IndexUpdatedEvent = {
  newIndexValue: string;
  oldIndexValue: string;
  totalUnitsApproved: string;
  totalUnitsPending: string;
  timestamp: number;
};

export type IndexSubscription = {
  id: string;
  units: number;
  totalAmountReceivedUntilUpdatedAt: string;
  index: Index;
  indexValueUntilUpdatedAt: number;
  subscriber: Account;
};

export type Token = {
  id: string;
  name: string;
  symbol: string;
  underlyingAddress: string;
  streams?: TokenStream[];
  accountWithToken?: Account[];
};

type Receiver = {
  id: string;
};

type Sender = {
  id: string;
};

export type TokenStream = {
  id: string;
  currentFlowRate: string;
  createdAtTimestamp: number;
  streamedUntilUpdatedAt: number;
  receiver: Receiver;
  sender: Sender;
  updatedAtTimestamp: number;
  events?: FlowUpdatedEvent[];
};

export type ModifiedFlowUpdatedEvent = FlowUpdatedEvent & {
  startDate: number;
  endDate: number;
  totalAmountStreamedUntilTimestampForNextEvent: number;
};

interface AccountResponse {
  account: Account | null;
}

function subgraphRequest<T>(
  url: string,
  query: string,
  args: any,
  retries: number
): Promise<T | undefined> {
  return new Promise(async (resolve) => {
    const logMessagePrefix = `
URL: ${url}
QUERY: 
${query}
ARGS: 
${JSON.stringify(args, null, 2)}
`;
    try {
      const resp = await request(url, query, args);
      DevConsoleLog(
        logMessagePrefix +
          `RESPONSE:
${JSON.stringify(resp, null, 2)}
`
      );
      resolve(resp as T);
    } catch (err) {
      DevConsoleLog(logMessagePrefix);
      console.error(err);
      if (retries <= 0) {
      } else {
        return await subgraphRequest<T>(url, query, args, retries - 1);
      }
    }
  });
}

export const fetchTokenById = async (
  id: string,
  chainId: string
): Promise<Token | undefined> => {
  const query = gql`
    query fetchTokenById($tokenId: String) {
      tokens(where: { id: $tokenId }) {
        id
        name
        symbol
        underlyingAddress
      }
      streams(
        first: 1000
        where: { token: $tokenId, currentFlowRate_not: "0" }
      ) {
        id
        currentFlowRate
        receiver {
          id
        }
        sender {
          id
        }
      }
      accountWithToken: accountTokenSnapshots(
        first: 1000
        where: { token: $tokenId }
      ) {
        account {
          id
        }
      }
    }
  `;
  const network = getNetworkByChainId(chainId);
  const resp = await subgraphRequest<any>(
    network.subgraphUrl,
    query,
    { tokenId: id.toLowerCase() },
    1
  );
  if (resp && resp.tokens && resp.tokens.length > 0) {
    return {
      ...resp.tokens[0],
      streams: resp.streams,
      accountWithToken: resp.accountWithToken,
    };
  }
};

export const fetchSuperTokensByUnderlyingAddress = async (
  address: string,
  chainId: string
): Promise<Token[]> => {
  const network = getNetworkByChainId(chainId);
  const tokenAddressQueryResponse = await subgraphRequest<any>(
    network.subgraphUrl,
    gql`
      query fetchTokensByUnderlyingAddress($underlyingAddress: String) {
        tokens(
          where: { underlyingAddress: $underlyingAddress, isSuperToken: true }
        ) {
          id
        }
      }
    `,
    { underlyingAddress: address.toLowerCase() },
    1
  );
  if (
    tokenAddressQueryResponse &&
    tokenAddressQueryResponse.tokens &&
    tokenAddressQueryResponse.tokens.length > 0
  ) {
    const promises = tokenAddressQueryResponse.tokens.map(
      (token: { id: string }) => fetchTokenById(token.id, chainId)
    );
    const tokenByIdResults: Token[] = await Promise.all(promises); // NOTE: There's SELECT N+1 problem here.
    return tokenByIdResults;
  }
  return [];
};

export const fetchTokenFlows = async (
  address: string,
  chainId: string,
  lastId = '',
  gt = true,
  limit = 50
): Promise<TokenStream[]> => {
  const paginationDir = gt ? 'gt' : 'lt';
  const orderExp = {
    gt: 'orderDirection: asc',
    lt: 'orderDirection: desc',
  };
  const query = gql`
    query fetchTokenFlows($tokenId: String, $lastId: String) {
      streams(first: ${limit}, orderBy: id, ${orderExp[paginationDir]}, where: { token: $tokenId, currentFlowRate_not: "0", id_${paginationDir}: $lastId }) {
        id
        currentFlowRate
        createdAtTimestamp
        streamedUntilUpdatedAt
        updatedAtBlockNumber
        receiver {
          id
        }
        sender {
          id
        }
      }
    }
  `;
  const network = getNetworkByChainId(chainId);
  const resp = await subgraphRequest<any>(
    network.subgraphUrl,
    query,
    { tokenId: address.toLowerCase(), lastId: lastId },
    1
  );
  if (resp && resp.streams && resp.streams.length) {
    return resp.streams;
  }
  return [];
};

export interface AddressSearchResult {
  superTokens: Array<{ id: string; symbol: string }>;
  account: { id: string } | null;
}

export const searchAddressQuery = async (
  address: string,
  chainId: string
): Promise<AddressSearchResult> => {
  const network = getNetworkByChainId(chainId);
  const query = gql`
    query searchAddress($address: String) {
      accounts(where: { id: $address }) {
        id
      }
      superTokensById: tokens(where: { id: $address, isSuperToken: true }) {
        id
        symbol
      }
      superTokensByUnderlyingAddress: tokens(
        where: { underlyingAddress: $address, isSuperToken: true }
      ) {
        id
        symbol
      }
    }
  `;
  const resp = await subgraphRequest<{
    accounts: Array<{ id: string }>;
    superTokensById: Array<{ id: string; symbol: string }>;
    superTokensByUnderlyingAddress: Array<{ id: string; symbol: string }>;
  }>(network.subgraphUrl, query, { address: address.toLowerCase() }, 1);

  if (!resp) {
    return {
      superTokens: [],
      account: null,
    };
  }

  const superTokens = resp.superTokensById.concat(
    resp.superTokensByUnderlyingAddress
  );
  const superTokenUniqueById = _.uniqBy(superTokens, (x) => x.id);
  const account = resp.accounts.length ? { id: resp.accounts[0].id } : null;

  return {
    superTokens: superTokenUniqueById,
    account: account,
  };
};

// Used just to check existence.
export const fetchAccountByAddress = async (
  address: string,
  chainId: string
): Promise<Account | undefined> => {
  const query = gql`
    query fetchAccountByAddress($accountAddress: String) {
      accounts(where: { id: $accountAddress }) {
        id
      }
    }
  `;
  const network = getNetworkByChainId(chainId);
  const resp = await subgraphRequest<any>(
    network.subgraphUrl,
    query,
    { accountAddress: address.toLowerCase() },
    1
  );
  if (resp && resp.accounts && resp.accounts.length > 0) {
    return resp.accounts[0];
  }
};

export const fetchAccount = async (
  address: string,
  chainId: string
): Promise<Account | undefined> => {
  const query = gql`
    query fetchAccount($accountAddress: String) {
      accountWithToken: accountTokenSnapshots(
        where: { account: $accountAddress }
      ) {
        id
        balanceUntilUpdatedAt
        token {
          id
          name
          symbol
          underlyingAddress
        }
      }
    }
  `;
  const network = getNetworkByChainId(chainId);
  const resp = await subgraphRequest<any>(
    network.subgraphUrl,
    query,
    { accountAddress: address.toLowerCase() },
    1
  );
  if (resp && resp.accountWithToken && resp.accountWithToken.length > 0) {
    return {
      id: address,
      accountWithToken: resp.accountWithToken,
    };
  }
};

export const fetchAccountWithStreams = async (
  address: string,
  chainId: string,
  tokenAddress: string,
  ownedLastCreatedAt = Math.round(new Date().getTime() / 1000).toString(),
  receivedLastCreatedAt = Math.round(new Date().getTime() / 1000).toString(),
  excludeInactive = false,
  excludeActive = false,
  lt = true,
  limit = 50
): Promise<Account | undefined> => {
  let excludeExp = '';
  const paginationDir = lt ? 'lt' : 'gt';
  if (excludeInactive) {
    excludeExp = ', currentFlowRate_not: "0"';
  } else if (excludeActive) {
    excludeExp = ', currentFlowRate: "0"';
  }
  const network = getNetworkByChainId(chainId);
  const query = gql`
    query fetchAccountWithFlows(
      $accountAddress: String
      $tokenAddress: String
      $ownedLastCreatedAt: String
      $receivedLastCreatedAt: String
    ) {
      account(id: $accountAddress) {
        id
        streamsOwned: outflows(first: ${limit}, orderBy: createdAtTimestamp, orderDirection: desc, where: { token: $tokenAddress, createdAtTimestamp_${paginationDir}: $ownedLastCreatedAt${excludeExp} }) {
          id
          currentFlowRate
          createdAtTimestamp
          streamedUntilUpdatedAt
          updatedAtTimestamp
          receiver {
            id
          }
          sender {
            id
          }
          events: flowUpdatedEvents(first: 1, orderBy: id, orderDirection: desc) {
            id
            oldFlowRate
            flowRate
            timestamp
            totalAmountStreamedUntilTimestamp
          }
        }
        
        streamsReceived: inflows(first: ${limit}, orderBy: createdAtTimestamp, orderDirection: desc, where: { token: $tokenAddress, createdAtTimestamp_${paginationDir}: $receivedLastCreatedAt${excludeExp} }) {
          id
          currentFlowRate
          createdAtTimestamp
          streamedUntilUpdatedAt
          updatedAtTimestamp
          receiver {
            id
          }
          sender {
            id
          }
          events: flowUpdatedEvents(first: 1, orderBy: id, orderDirection: desc) {
            id  
            oldFlowRate
            flowRate
            timestamp
            totalAmountStreamedUntilTimestamp
          }
        }
        
      }
      
    }
  `;
  const resp = await subgraphRequest<AccountResponse>(
    network.subgraphUrl,
    query,
    {
      tokenAddress: tokenAddress.toLowerCase(),
      accountAddress: address.toLowerCase(),
      ownedLastCreatedAt: ownedLastCreatedAt,
      receivedLastCreatedAt: receivedLastCreatedAt,
    },
    1
  );

  if (resp?.account) {
    return resp.account;
  }
};

export const fetchAccountStreams = async (
  address: string,
  chainId: string,
  tokenAddress: string,
  ownedLastCreatedAt = Math.round(new Date().getTime() / 1000).toString(),
  receivedLastCreatedAt = Math.round(new Date().getTime() / 1000).toString(),
  excludeInactive = false,
  excludeActive = true,
  limit = 50,
  lt = false
): Promise<TokenStream[]> => {
  try {
    const account = await fetchAccountWithStreams(
      address,
      chainId,
      tokenAddress,
      ownedLastCreatedAt,
      receivedLastCreatedAt,
      excludeInactive,
      excludeActive,
      lt,
      limit
    );
    if (account) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return [...account.streamsOwned!, ...account.streamsReceived!].sort(
        (a, b) => {
          return b.createdAtTimestamp - a.createdAtTimestamp;
        }
      );
    }
  } catch (err) {
    console.error(err);
    console.error('Failed to fetch account flows');
    return [];
  }
  return [];
};

export const fetchStreamEvents = async (
  chainId: string,
  streamId: string,
  lastId = '',
  gt = true
): Promise<FlowUpdatedEvent[]> => {
  const paginationDir = gt ? 'gt' : 'lt';
  const query = gql`
    query fetchStreamEvents($streamId: String, $lastId: String) {
      flowUpdatedEvents(first: 20, where: { id_${paginationDir}: $lastId, stream: $streamId }) {
        id
        oldFlowRate
        flowRate
		timestamp
		totalAmountStreamedUntilTimestamp
      }
    }
  `;
  const network = getNetworkByChainId(chainId);
  const resp = await subgraphRequest<any>(
    network.subgraphUrl,
    query,
    {
      streamId: streamId.toLowerCase(),
      lastId: lastId,
    },
    1
  );
  if (resp && resp.flowUpdatedEvents.length > 0) {
    return resp.flowUpdatedEvents;
  }
  return [];
};

export const fetchIndexSubscriptions = async (
  accountAddress: string,
  chainId: string,
  lastId = '',
  gt = true,
  limit = 50,
  approved: null | boolean = null
): Promise<IndexSubscription[]> => {
  const paginationDir = gt ? 'gt' : 'lt';
  const orderExp = {
    gt: 'orderDirection: asc',
    lt: 'orderDirection: desc',
  };
  let approvedExp = '';
  if (approved !== null) {
    approvedExp = `, approved: ${approved}`;
  }

  const query = gql`
    query fetchIndexSubscriptions($accountAddress: String, $lastId: String) {
      indexSubscriptions(first: ${limit}, orderBy: id, ${orderExp[paginationDir]}, where: { subscriber: $accountAddress, id_${paginationDir}: $lastId${approvedExp}}) {
        id
        units
        subscriber {
            id
        }
        totalAmountReceivedUntilUpdatedAt
        indexValueUntilUpdatedAt
        index { 
          id
          indexValue
          totalUnits
          token {
            id
            name
            symbol
            underlyingAddress
          }
          publisher {
            id
          }
        }
      }
    }
  `;
  const network = getNetworkByChainId(chainId);
  const resp = await subgraphRequest<any>(
    network.subgraphUrl,
    query,
    {
      accountAddress: accountAddress.toLowerCase(),
      lastId: lastId,
    },
    1
  );
  if (resp && resp.indexSubscriptions.length > 0) {
    return resp.indexSubscriptions;
  }
  return [];
};

export const fetchIndexes = async (
  accountAddress: string,
  chainId: string,
  lastId = '',
  gt = true,
  limit = 50
): Promise<Index[]> => {
  const paginationDir = gt ? 'gt' : 'lt';
  const orderExp = {
    gt: 'orderDirection: asc',
    lt: 'orderDirection: desc',
  };

  const query = gql`
    query fetchIndexes($accountAddress: String, $lastId: String) {
      indexes(first: ${limit}, orderBy: id, ${orderExp[paginationDir]}, where: { publisher: $accountAddress, id_${paginationDir}: $lastId}) {
        id
        totalUnits
        totalAmountDistributedUntilUpdatedAt
        token {
          id
          name
          symbol
          underlyingAddress
        }
      }
    }
  `;
  const network = getNetworkByChainId(chainId);
  const resp = await subgraphRequest<any>(
    network.subgraphUrl,
    query,
    {
      accountAddress: accountAddress.toLowerCase(),
      lastId: lastId,
    },
    1
  );
  if (resp && resp.indexes.length > 0) {
    return resp.indexes;
  }
  return [];
};

export const fetchIndexUpdatedEvents = async (
  id: string,
  chainId: string,
  limit = 50
): Promise<IndexUpdatedEvent[]> => {
  const query = gql`
    query fetchIndexUpdates($publisher: String, $tokenId: String, $indexId: String) {
      indexUpdatedEvents(first: ${limit}, orderBy: timestamp, orderDirection: desc, where: { token: $tokenId, publisher: $publisher, indexId: $indexId}) {
        id
        newIndexValue
        oldIndexValue
        totalUnitsApproved
        totalUnitsPending
        timestamp
      }
    }
  `;
  const network = getNetworkByChainId(chainId);
  const [publisher, tokenId, indexId] = id.toLowerCase().split('-');
  const resp = await subgraphRequest<any>(
    network.subgraphUrl,
    query,
    {
      publisher,
      tokenId,
      indexId,
    },
    1
  );
  if (resp && resp.indexUpdatedEvents.length > 0) {
    return resp.indexUpdatedEvents;
  }
  return [];
};

export const fetchSubscriberUnitUpdatedEvents = async (
  subscriptionId: string,
  chainId: string,
  lastId = '',
  gt = true,
  limit = 50
): Promise<SubscriptionUnitsUpdatedEvent[]> => {
  const paginationDir = gt ? 'gt' : 'lt';
  const orderExp = {
    gt: 'orderDirection: desc',
    lt: 'orderDirection: asc',
  };
  const query = gql`
    query fetchSubscriberUnitUpdates($subscriptionId: String, $lastId: String) {
      subscriptionUnitsUpdatedEvents(first: ${limit}, orderBy: timestamp, ${orderExp[paginationDir]}, where: { subscription: $subscriptionId, id_${paginationDir}: $lastId}) {
          id
          units
          timestamp
        }
      }
`;
  const network = getNetworkByChainId(chainId);
  const resp = await subgraphRequest<any>(
    network.subgraphUrl,
    query,
    {
      subscriptionId: subscriptionId.toLowerCase(),
      lastId: lastId,
    },
    1
  );

  if (resp && resp.subscriptionUnitsUpdatedEvents.length > 0) {
    return resp.subscriptionUnitsUpdatedEvents;
  }
  return [];
};
