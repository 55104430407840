import styled from 'styled-components';

export const Table = styled.table`
  border: 1px solid var(--tertiary-bg-color);
  border-collapse: collapse;
`;

export const TableHeader = styled.thead``;

export const TableRow = styled.tr`
  border: 1px solid var(--tertiary-bg-color);
  :hover {
    background: var(--secondary-bg-color);
  }
`;

export const TableBody = styled.tbody``;

export const TableHeaderData = styled.th`
  padding: 1rem;
  border: 1px solid var(--tertiary-bg-color);
  background: white !important;
  cursor: auto;
`;

export const TableData = styled.td`
  padding: 1rem;
  border: 1px solid var(--tertiary-bg-color);
  text-align: center;
`;
