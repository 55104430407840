import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Blockies from 'react-blockies';

import EditName from '../components/EditName';
import { LayoutBody } from '../components/Layout';
import {
  Table,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderData,
  TableData,
} from '../components/Table';

import { getNetworkByName } from '../lib/networks';
import {
  Address,
  getAddresses,
  getSearchHistory,
  getHistoryAddress,
} from '../lib/localStorage';
import { EntityType } from '../hooks/useAddressSearch';
import { addressTypeToUrlName } from '../lib/addressHelpers';

const GridBody = styled(LayoutBody)`
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: text;
  padding: 0.5rem 1.2rem 0.5rem 1.2rem;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const AddressesPage: React.FC = () => {
  const addresses = getAddresses();
  const searchHistory = getSearchHistory();
  const history = useHistory();

  const handleRowClick = (e: any) => {
    if (!e.target.dataset.index) {
      return;
    }
    const [addressKey, chain] = e.target.dataset.index.split('-');
    const address = getHistoryAddress(addressKey, chain);
    if (address) {
      history.push(
        `${address.chain}/${addressTypeToUrlName(address.type)}/${
          address.type == ('Token' as EntityType) ? address.id : address.address
        }`
      );
    }
  };

  return (
    <GridBody>
      <h1>Search History</h1>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderData>Name</TableHeaderData>
            <TableHeaderData>Address</TableHeaderData>
            <TableHeaderData>Type</TableHeaderData>
            <TableHeaderData>Chain</TableHeaderData>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.entries(searchHistory)
            .reverse()
            .map(([key, value]) => (
              <TableRow key={key} data-index={key}>
                <TableData data-index={key}>
                  <FlexRow onClick={handleRowClick}>
                    <Blockies seed={value.address} />
                    <EditContainer>
                      <EditName
                        address={
                          addresses[key] ||
                          ({
                            name: value.name,
                            address: value.address,
                            type: value.type,
                            chain: value.chain,
                          } as Address)
                        }
                      />
                    </EditContainer>
                  </FlexRow>
                </TableData>
                <TableData data-cy={'full-address'} data-index={key}>
                  <FlexRow>{value.address}</FlexRow>
                </TableData>
                <TableData data-cy={'type'} data-index={key}>
                  <FlexRow>{value.type}</FlexRow>
                </TableData>
                <TableData data-cy={'network'} data-index={key}>
                  {getNetworkByName(value.chain)?.name}
                </TableData>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </GridBody>
  );
};

export default AddressesPage;
