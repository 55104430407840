import React from 'react';
import styled from 'styled-components';

type Props = {
  header: string;
  metadata: string;
};

const MetadataArea = styled.div``;

const Metadata: React.FC<Props> = ({ header, metadata }) => {
  return (
    <MetadataArea>
      <h2>{header}</h2>
      <p
        data-cy={'metadata-' + header.toLocaleLowerCase().replaceAll(' ', '-')}
      >
        {metadata}
      </p>
    </MetadataArea>
  );
};

export default Metadata;
