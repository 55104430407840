import React from 'react';
import styled from 'styled-components';

import superfluidLogo from '../assets/logo.svg';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  height: 3rem;
`;

const LogoFooter = styled.span``;

const SuperfluidLogo: React.FC = () => (
  <LogoContainer>
    <Logo src={superfluidLogo} alt="Superfluid logo" />
    <LogoFooter>BETA</LogoFooter>
  </LogoContainer>
);

export default SuperfluidLogo;
