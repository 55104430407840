import React, { useEffect, useState, useCallback } from 'react';
import {
  ModifiedFlowUpdatedEvent,
  TokenStream,
  fetchStreamEvents,
} from '../graphql';
import EventRow from '../components/EventRow';

import { getLastElement } from '../lib/addressHelpers';

type Props = {
  stream: TokenStream;
  chainId: string;
  blocktime: number;
};

const Stream: React.FC<Props> = ({ stream, chainId, blocktime }) => {
  const [events, setEvents] = useState<ModifiedFlowUpdatedEvent[]>([]);
  const [lastId, setLastId] = useState('');

  const fetchEvents = useCallback(
    async (forward = true) => {
      const fetchedEvents = await fetchStreamEvents(
        chainId,
        stream.id,
        lastId,
        forward
      );
      const sortedFetchedEvents = fetchedEvents.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });

      const eventsLength = sortedFetchedEvents.length;
      const modifiedEvents: ModifiedFlowUpdatedEvent[] = [];
      for (let i = 0; i < eventsLength; i++) {
        if (!sortedFetchedEvents) {
          break;
        }
        const event = sortedFetchedEvents[i];
        if (event.flowRate === '0') {
          continue;
        }
        if (i + 1 >= eventsLength) {
          // the top event
          modifiedEvents.push({
            ...event,
            startDate: event.timestamp,
            endDate: 0,
            totalAmountStreamedUntilTimestampForNextEvent: 0,
          });
          continue;
        }
        const nextEvent = sortedFetchedEvents[i + 1];
        const modifiedEvent = {
          ...event,
          startDate: event.timestamp,
          endDate: nextEvent.timestamp,
          totalAmountStreamedUntilTimestampForNextEvent:
            nextEvent.totalAmountStreamedUntilTimestamp,
        };
        modifiedEvents.push(modifiedEvent);
      }
      const sortedEvents = modifiedEvents.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });

      setEvents(sortedEvents);
      const el = getLastElement(sortedEvents);
      if (el) {
        setLastId(el.id);
      }
    },
    [stream.id, chainId, lastId]
  );

  useEffect(() => {
    fetchEvents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {events.map((event, index) => (
        <EventRow
          index={index}
          flow={stream}
          event={event}
          blocktime={blocktime}
          key={event.id}
        />
      ))}
    </>
  );
};

export default Stream;
