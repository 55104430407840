import { createGlobalStyle } from 'styled-components';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import walsheimFont from './assets/GTWalsheimProRegular.woff2';

const GlobalStyle = createGlobalStyle`
    html {
        height: 100%
    }

    body {
       height: 100%;
       margin: 0px;
       padding: 0px;
       background-color: rgb(252, 253, 254);
    }

    #root {
        height: 100%;
    }

    input:focus{
      outline: none;
    }

    input:hover{
      border: 0.1rem solid #c4c4c4;
    }

		a {
		  text-decoration: none;
			color: inherit;
		}

    input,
    select,
    textarea{
      font-size: 1rem;
      padding: 0.5rem;
      border-radius: 0.2rem;
      border: 0.1rem solid #E0E8F8;
    }

    button.primary:hover {
      background-color: var(--primary-hover-color);
    }

    button.account:hover {
      background-color: var(--account-hover-color);
    }

		// TODO: Not setting correct font
    @font-face {
        font-family: 'GT Walsheim Pro Regular';
        src: local('GT Walsheim Pro Regular'), url(${walsheimFont}) format('woff2'),
    }

		:root {
      --primary-font-color: white;
		  --primary-bg-color: rgb(16, 187, 52);
			--primary-hover-color: rgb(18, 210, 59);
      --secondary-font-color: rgb(18, 210, 59);
			--secondary-hover-color: rgb(9, 77, 25);
			--secondary-bg-color: rgb(217, 244, 222);
			--secondary-placeholder-color: rgb(217, 244, 222);
			--tertiary-bg-color: rgb(224 232 248);

			--border-radius: 0.2rem;

      --account-font-color: white;
      --account-bg-color: rgb(114, 100, 243);
      --account-hover-color: rgb(116, 123, 250);
		}

`;

export default GlobalStyle;
