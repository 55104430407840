import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import type {} from 'styled-components/cssprop';
import { makeStyles } from '@material-ui/core/styles';
import store from './redux/store';
import './index.css';
import Routes from './Routes';
import { StreamProvider } from './context/StreamContext';
import { HashRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';

import GlobalStyle from './GlobalStyle';
import { CaptureConsole } from '@sentry/integrations';

const classes = {
  success: {
    background: 'var(--primary-bg-color)',
    color: 'var(--primary-font-color)',
  },
};

const useStyles = makeStyles(classes);

if (process.env.REACT_APP_SENTRY_DNS_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS_URL,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 0.0,
  });
}

const Root: React.FC = () => {
  const styles = useStyles();
  return (
    <Provider store={store}>
      <React.StrictMode>
        <Router>
          <SnackbarProvider classes={{ variantSuccess: styles.success }}>
            <StreamProvider>
              <Routes />
            </StreamProvider>
          </SnackbarProvider>
          <GlobalStyle />
        </Router>
      </React.StrictMode>
    </Provider>
  );
};
ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
