// import "@reach/listbox/styles.css";
import React from 'react';
import styled from 'styled-components';
import { useStreamContext } from '../context/StreamContext';

import { ListboxOption, Listbox } from '@reach/listbox';

const LabelStyled = styled.label`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const StreamGranularity: React.FC = () => {
  const { flowRateGranularity, setFlowRateGranularity } = useStreamContext();
  const handleChange = (value: string) => {
    setFlowRateGranularity(parseInt(value, 10));
  };
  return (
    <>
      <LabelStyled>Stream Time</LabelStyled>
      <Listbox
        data-cy={'stream-time-dropdown'}
        onChange={handleChange}
        value={flowRateGranularity.toString()}
      >
        <ListboxOption value="1">Seconds</ListboxOption>
        <ListboxOption value={`${60}`}>Minutes</ListboxOption>
        <ListboxOption value={`${60 * 60}`}>Hours</ListboxOption>
        <ListboxOption value={`${60 * 60 * 24}`}>Days</ListboxOption>
        <ListboxOption value={`${60 * 60 * 24 * 7}`}>Weeks</ListboxOption>
        <ListboxOption value={`${60 * 60 * 24 * 30}`}>30 Days</ListboxOption>
      </Listbox>
    </>
  );
};

export default StreamGranularity;
