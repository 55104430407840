import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import FormattedDate from '../components/FormattedDate';
import { Tr, Td } from './CurrencyTable';
import { getAddressName } from '../lib/addressHelpers';
import {
  fetchSubscriberUnitUpdatedEvents,
  IndexSubscription,
  SubscriptionUnitsUpdatedEvent,
} from '../graphql';
import { ChainType } from '../hooks/useAddressSearch';
import { getNetworkByName } from '../lib/networks';
import { BigNumber, utils } from 'ethers';
import FormattedNumber from './FormattedNumber';

type Props = {
  subscription: IndexSubscription;
  chainName: ChainType;
};

const calculateUnitsReceived = (
  publisherIndexValue: BigNumber,
  subscriberTotalAmountReceivedUntilUpdatedAt: BigNumber,
  subscriberIndexValueUntilUpdatedAt: BigNumber,
  subscriberUnits: number
) => {
  const totalUnitsReceived = subscriberTotalAmountReceivedUntilUpdatedAt.add(
    publisherIndexValue
      .sub(subscriberIndexValueUntilUpdatedAt)
      .mul(subscriberUnits)
  );

  return totalUnitsReceived;
};

const SubscriberRow: React.FC<Props> = ({ subscription, chainName }) => {
  const [subscriberUnitUpdatedEvents, setSubscriberUnitUpdatedEvents] =
    useState<SubscriptionUnitsUpdatedEvent[]>([]);

  useEffect(() => {
    const getUpdate = async () => {
      const network = getNetworkByName(chainName);
      if (network && subscription) {
        setSubscriberUnitUpdatedEvents(
          await fetchSubscriberUnitUpdatedEvents(subscription.id, network.id)
        );
      }
    };
    getUpdate();
  }, [subscription, chainName]);

  const totalUnitsReceived = calculateUnitsReceived(
    BigNumber.from(subscription.index.indexValue),
    BigNumber.from(subscription.totalAmountReceivedUntilUpdatedAt),
    BigNumber.from(subscription.indexValueUntilUpdatedAt),
    subscription.units
  );

  return (
    <>
      {subscriberUnitUpdatedEvents.map((update, index) => (
        <Tr className="child" key={update.id}>
          <Td>
            {index === 0 && (
              <>
                <span data-tip={subscription.index.publisher.id}>
                  <NavLink to={subscription.index.publisher.id}>
                    {getAddressName(subscription.index.publisher.id, chainName)}
                  </NavLink>
                </span>
                <ReactTooltip place="top" effect="solid" />
              </>
            )}
          </Td>
          <Td>
            {index === 0 && (
              <>
                <span data-tip={subscription.index.token.id}>
                  <NavLink
                    to={`/${chainName}/supertoken/${subscription.index.token.id}`}
                  >
                    {subscription.index.token.name
                      ? `${subscription.index.token.name} (${subscription.index.token.symbol})`
                      : getAddressName(
                          subscription.index.token.id || '',
                          chainName
                        )}
                  </NavLink>
                </span>
                <ReactTooltip place="top" effect="solid" />
              </>
            )}
          </Td>
          <Td>{index === 0 && subscription.index.totalUnits}</Td>
          <Td>
            {index === 0 && (
              <>
                <FormattedNumber symbol={subscription.index.token.symbol}>
                  {utils.formatEther(totalUnitsReceived.toString())}
                </FormattedNumber>{' '}
              </>
            )}
          </Td>
          <Td colSpan={2}>
            {index === 0 ? (
              <>
                <FormattedDate unixTimestamp={update.timestamp} />
                <span> to </span>
                <span>Now</span>
              </>
            ) : (
              <>
                <FormattedDate
                  unixTimestamp={
                    subscriberUnitUpdatedEvents[index - 1].timestamp
                  }
                />
                <span> to </span>
                <FormattedDate unixTimestamp={update.timestamp} />
              </>
            )}
          </Td>
          <Td>{update.units}</Td>
        </Tr>
      ))}
    </>
  );
};

export default SubscriberRow;
