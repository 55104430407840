import React, { useCallback, useContext, useState } from 'react';

export type StreamContextValue = {
  flowRateGranularity: number;
  setFlowRateGranularity: (arg0: number) => void;
};

export const StreamContext = React.createContext({
  flowRateGranularity: 1,
  setFlowRateGranularity: (flowRateGranularity: number) => {
    console.debug(flowRateGranularity);
  },
});

export const useStreamContext: () => StreamContextValue = () =>
  useContext(StreamContext);

export const StreamProvider: React.FC = ({ children }) => {
  const [granularity, setGranularity] = useState(1);

  const memoizedSetFlowRateGranularityMetadata = useCallback(
    (granularity: number) => {
      setGranularity(granularity);
    },
    []
  );

  return (
    <StreamContext.Provider
      value={{
        flowRateGranularity: granularity,
        setFlowRateGranularity: memoizedSetFlowRateGranularityMetadata,
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};
