import { providers } from 'ethers';

type Network = {
  id: string;
  name: string;
  subgraphUrl: string;
  url: string;
  testnet: boolean;
};

type Networks = {
  [key: number]: Network;
};

function ensureDefined<T>(value: T | undefined): T {
  if (!value) throw Error('Value has to be defined.');
  return value;
}

export const networks: Networks = {
  3: {
    id: '3',
    name: 'Ropsten',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_ROPSTEN),
    url: `https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    testnet: true,
  },
  4: {
    id: '4',
    name: 'Rinkeby',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_RINKEBY),
    url: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    testnet: true,
  },
  5: {
    id: '5',
    name: 'Goerli',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_GOERLI),
    url: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    testnet: true,
  },
  42: {
    id: '42',
    name: 'Kovan',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_KOVAN),
    url: `https://kovan.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    testnet: true,
  },
  100: {
    id: '100',
    name: 'xDAI',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_XDAI),
    url: 'https://rpc.xdaichain.com/',
    testnet: false,
  },
  137: {
    id: '137',
    name: 'Matic',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_MATIC),
    url: `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    testnet: false,
  },
  8001: {
    id: '8001',
    name: 'Mumbai',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_MUMBAI),
    url: `https://polygon-mumbai.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    testnet: true,
  },
  421611: {
    id: '421611',
    name: 'Arbitrum-Rinkeby',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_ARBITRUM_RINKEBY),
    url: `https://arbitrum-rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    testnet: true,
  },
  69: {
    id: '69',
    name: 'Optimism-Kovan',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_OPTIMISM_KOVAN),
    url: `https://optimism-kovan.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    testnet: true,
  },
  43113: {
    id: '43113',
    name: 'Avalanche-Fuji',
    subgraphUrl: ensureDefined(process.env.REACT_APP_SUBGRAPH_AVALANCHE_FUJI),
    url: `https://api.avax-test.network/ext/bc/C/rpc`,
    testnet: true,
  },
};

const nameToId: { [key: string]: string } = {
  ropsten: '3',
  rinkeby: '4',
  goerli: '5',
  kovan: '42',
  xdai: '100',
  matic: '137',
  mumbai: '8001',
  'avalanche-fuji': '43113',
  'arbitrum-rinkeby': '421611',
  'optimism-kovan': '69',
};

export const getNetworkByChainId = (chainId: string): Network => {
  const network = networks[parseInt(chainId)];
  return network;
};

export const getNetworkByName = (name: string): Network | null => {
  const nameLower = name.toLowerCase();
  const chainId = nameToId[nameLower];
  if (chainId) {
    return getNetworkByChainId(chainId);
  }
  return null;
};

export const getProviderByChainName = async (
  chainName: string
): Promise<providers.BaseProvider> => {
  const network = getNetworkByName(chainName);

  return providers.getDefaultProvider(network?.url);
};
