import React from 'react';
import styled from 'styled-components';

const GridFooter = styled.div`
  grid-area: foot;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
`;

const Footer: React.FC = () => {
  return (
    <GridFooter>
      <p
        style={{
          opacity: 0.4,
        }}
      >
        By using this web app, you accept our{' '}
        <a
          href="https://www.superfluid.finance/termsofuse/"
          target="_blank"
          rel="noreferrer"
        >
          <strong data-cy={'terms-link'}>Terms of Use</strong>
        </a>{' '}
        and{' '}
        <a
          href="https://www.iubenda.com/privacy-policy/34415583/legal"
          target="_blank"
          rel="noreferrer"
        >
          <strong data-cy={'privacy-link'}>Privacy Policy</strong>
        </a>
        .
      </p>
    </GridFooter>
  );
};

export default Footer;
