import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/fp/debounce';

import { networks, getNetworkByChainId } from '../lib/networks';
import { searchAddressQuery } from '../graphql';

import '@reach/combobox/styles.css';
import { getShortenAddress } from '../lib/addressHelpers';
import { isAddress } from 'ethers/lib/utils';
import _ from 'lodash';

export type EntityType = 'Token' | 'Account' | 'SuperApp';
export type ChainType =
  | 'ropsten'
  | 'xdai'
  | 'rinkeby'
  | 'goerli'
  | 'kovan'
  | 'matic'
  | 'mumbai';

interface SuperfluidEntity {
  id: string;
}

export type SearchResult = {
  type: EntityType;
  entity: SuperfluidEntity;
  isTestnet: boolean;
  network: string;
  address: string;
  shortDescription?: string;
};

type Search = {
  addressSearchResults: SearchResult[];
  loading: boolean;
};

const searchEntities = async (
  address: string,
  chainId: string
): Promise<SearchResult[]> => {
  const entities: SearchResult[] = [];
  const network = getNetworkByChainId(chainId);

  const { account, superTokens } = await searchAddressQuery(address, chainId);
  if (account) {
    entities.push({
      type: 'Account',
      entity: account,
      isTestnet: network.testnet,
      network: network.name,
      address: account.id,
      shortDescription: '',
    });
  }
  for (const superToken of superTokens) {
    entities.push({
      type: 'Token',
      entity: superToken,
      isTestnet: network.testnet,
      network: network.name,
      address: superToken.id,
      shortDescription: `(${superToken.symbol}${
        superTokens.length >= 2 ? `, ${getShortenAddress(superToken.id)}` : ''
      })`,
    });
  }
  return entities;
};

export const useAddressSearch = (searchAddress: string): Search => {
  const [entities, setEntities] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);

  const searchSubgraphs = async (address: string) => {
    const promises = _.keys(networks).map((chainId) =>
      searchEntities(address, chainId)
    );
    const results = await Promise.all(promises);
    const searchResults: SearchResult[] = [];
    for (const index in results) {
      const result = results[index];
      if (result[0] && result[0].isTestnet) {
        searchResults.push(...result);
      } else {
        searchResults.unshift(...result);
      }
    }
    setEntities(searchResults);
    setLoading(false);
  };

  // eslint-disable-next-line
  const searchSubgraphsDebounce = useCallback(
    debounce(400, searchSubgraphs),
    []
  );

  useEffect(() => {
    setEntities([]);
    if (isAddress(searchAddress)) {
      setLoading(true);
      searchSubgraphsDebounce(searchAddress);
    }
  }, [searchAddress, searchSubgraphsDebounce]);
  return { addressSearchResults: entities, loading };
};
