import React from 'react';
import styled from 'styled-components';

import AddressSearch from '../components/AddressSearch';
import { LayoutBody } from '../components/Layout';

const GridBody = styled(LayoutBody)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WelcomeHeading = styled.h1`
  font-size: 3rem;
`;

const FrontPage: React.FC = () => {
  return (
    <GridBody>
      <WelcomeHeading>Welcome to Superfluid Console (Beta)</WelcomeHeading>
      <AddressSearch />
    </GridBody>
  );
};

export default FrontPage;
