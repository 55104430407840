import { createStore } from 'redux';

import { useDispatch } from 'react-redux';
import rootReducer from './reducer';

export declare type fEmptyAny = () => any;

const store = createStore(rootReducer);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): any => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
