import React from 'react';
import ReactTooltip from 'react-tooltip';
import { convertUnixTimestamp } from '../lib/dateHelpers';

type Props = {
  unixTimestamp: number;
};

const FormattedDate: React.FC<Props> = ({ unixTimestamp }) => {
  return (
    <>
      <span data-tip={unixTimestamp}>
        {convertUnixTimestamp(unixTimestamp)}
      </span>
      <ReactTooltip place="top" effect="solid" />
    </>
  );
};

export default FormattedDate;
