import React from 'react';
import styled from 'styled-components';
import Blockies from 'react-blockies';

import EditName from '../components/EditName';
import { LayoutBody } from '../components/Layout';
import {
  Table,
  TableRow,
  TableBody,
  TableHeader,
  TableHeaderData,
  TableData,
} from '../components/Table';

import { getAddresses } from '../lib/localStorage';
import { getNetworkByName } from '../lib/networks';

const GridBody = styled(LayoutBody)`
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const FlexRow = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: text;
  padding: 0.5rem 1.2rem 0.5rem 1.2rem;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const AddressesPage: React.FC = () => {
  const addresses = getAddresses();

  return (
    <GridBody>
      <h1>Address Book</h1>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderData>Name</TableHeaderData>
            <TableHeaderData>Address</TableHeaderData>
            <TableHeaderData>Type</TableHeaderData>
            <TableHeaderData>Chain</TableHeaderData>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.entries(addresses).map(([key, value]) => (
            <TableRow key={key} data-index={key}>
              <TableData data-index={key}>
                <FlexRow>
                  <Blockies seed={value.address} />
                  <EditContainer>
                    <EditName address={value} />
                  </EditContainer>
                </FlexRow>
              </TableData>
              <TableData data-cy={'full-address'} data-index={key}>
                <FlexRow>{value.address}</FlexRow>
              </TableData>
              <TableData data-cy={'type'} data-index={key}>
                <FlexRow>{value.type}</FlexRow>
              </TableData>
              <TableData data-cy={'network'} data-index={key}>
                {getNetworkByName(value.chain)?.name}
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </GridBody>
  );
};

export default AddressesPage;
