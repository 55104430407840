import React, { useEffect, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BigNumber, utils } from 'ethers';
import {
  Index,
  fetchIndexes,
  fetchIndexUpdatedEvents,
  IndexUpdatedEvent,
} from '../graphql';
import { getNetworkByChainId } from '../lib/networks';
import { getAddressName, getLastElement } from '../lib/addressHelpers';
import { Tr, Td, Bold } from '../components/CurrencyTable';
import FormattedDate from '../components/FormattedDate';
import { LoadMore } from '../components/LoadMore';
import SuperLoader from '../components/SuperLoader';
import ReactTooltip from 'react-tooltip';
import { ChainType } from '../hooks/useAddressSearch';

import { IDA_PAGE_LENGTH } from '../constants';
import FormattedNumber from './FormattedNumber';

type Props = {
  address: string;
  chainId: string;
};

export const PublisherTable: React.FC<Props> = ({ address, chainId }) => {
  const [indexes, setIndexes] = useState<Index[]>([]);
  const [newIndexesCount, setNewIndexesCount] = useState(0);
  const [lastId, setLastId] = useState('');
  const [loading, setLoading] = useState(false);

  const getIndexes = useCallback(
    async (gt = true) => {
      const pageSize = IDA_PAGE_LENGTH;
      if (chainId) {
        setLoading(true);
        const newIndexes = await fetchIndexes(
          address,
          chainId,
          lastId,
          gt,
          pageSize
        );
        setLoading(false);

        setNewIndexesCount(newIndexes.length);
        if (newIndexes.length <= 0) {
          return;
        }
        setIndexes([...indexes, ...newIndexes]);

        if (newIndexes.length >= pageSize) {
          const last = getLastElement(newIndexes || []);
          if (last !== null) {
            setLastId(last.id);
          }
        }
      }
    },
    [chainId, address, lastId, indexes]
  );

  const handlePageForward = () => {
    getIndexes();
  };

  useEffect(() => {
    getIndexes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Td>
        <Bold>Token</Bold>
      </Td>
      <Td>
        <Bold>Total Units</Bold>
      </Td>
      <Td>
        <Bold>Total Distributed</Bold>
      </Td>
      <Td>
        <Bold>Total Units Approved</Bold>
      </Td>
      <Td>
        <Bold>Total Units Pending</Bold>
      </Td>
      <Td>
        <Bold>Distribution Date</Bold>
      </Td>
      <Td>
        <Bold>Distribution Amount</Bold>
      </Td>
      {indexes.map((index) => (
        <IndexRow index={index} key={index.id} chainId={chainId} />
      ))}
      {loading && (
        <tr>
          <td colSpan={7}>
            <SuperLoader />
          </td>
        </tr>
      )}
      {indexes.length >= 1 ? (
        <Tr key="row">
          <Td colSpan={7}>
            <LoadMore
              disabled={newIndexesCount < IDA_PAGE_LENGTH}
              handleLoadMore={handlePageForward}
            />
          </Td>
        </Tr>
      ) : (
        <></>
      )}
    </>
  );
};

type IndexRowProps = {
  index: Index;
  chainId: string;
};

export const IndexRow: React.FC<IndexRowProps> = ({ index, chainId }) => {
  const [indexUpdatedEvents, setIndexUpdatedEvents] = useState<
    IndexUpdatedEvent[]
  >([]);
  const network = getNetworkByChainId(chainId);

  useEffect(() => {
    const getUpdates = async () => {
      const indexUpdates = await fetchIndexUpdatedEvents(index.id, chainId);
      setIndexUpdatedEvents(indexUpdates);
    };
    getUpdates();
  }, [chainId, index.id]);

  const calculateDistributionAmount = (event: IndexUpdatedEvent) => {
    return BigNumber.from(event.newIndexValue)
      .mul(index.totalUnits)
      .sub(BigNumber.from(event.oldIndexValue).mul(index.totalUnits));
  };

  return (
    <>
      {indexUpdatedEvents ? (
        indexUpdatedEvents.map((indexUpdateEvent, idx) => (
          <Tr className="child" key={idx}>
            <Td>
              {idx === 0 ? (
                <>
                  <span data-tip={index.token.id}>
                    <NavLink
                      to={`/${network.name.toLowerCase()}/supertoken/${
                        index.token.id
                      }`}
                    >
                      {index.token.name
                        ? `${index.token.name} (${index.token.symbol})`
                        : getAddressName(
                            index.token.id,
                            network.name.toLowerCase() as ChainType
                          )}
                    </NavLink>
                  </span>
                  <ReactTooltip place="top" effect="solid" />
                </>
              ) : (
                <></>
              )}
            </Td>
            <Td>{idx === 0 && index.totalUnits}</Td>
            <Td>
              {idx === 0 && (
                <FormattedNumber symbol={index.token.symbol}>
                  {utils.formatEther(
                    BigNumber.from(index.totalAmountDistributedUntilUpdatedAt)
                  )}
                </FormattedNumber>
              )}
            </Td>
            <Td>{indexUpdateEvent.totalUnitsApproved}</Td>
            <Td>{indexUpdateEvent.totalUnitsPending}</Td>
            <Td>
              <FormattedDate unixTimestamp={indexUpdateEvent.timestamp} />
            </Td>
            <Td>
              {
                <FormattedNumber symbol={index.token.symbol}>
                  {utils.formatEther(
                    calculateDistributionAmount(indexUpdateEvent)
                  )}
                </FormattedNumber>
              }
            </Td>
          </Tr>
        ))
      ) : (
        <></>
      )}
    </>
  );
};
