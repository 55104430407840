import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ADDRESSES_PATH } from './constants';
import AccountPage from './pages/AccountPage';
import SuperTokenPage from './pages/SuperTokenPage';
import FrontPage from './pages/FrontPage';
import AddressesPage from './pages/AddressesPage';
import FavoritesPage from './pages/SearchHistoryPage';
import SubgraphPage from './pages/SubgraphPage';
import { MainLayout } from './components/Layout';

const Routes: React.FC = () => {
  const location = useLocation();

  return (
    <Switch
      key={
        location.pathname +
        '@' +
        location.hash +
        '@' +
        location.search.toString()
      }
    >
      <Route exact path="/">
        <MainLayout>
          <FrontPage />
        </MainLayout>
      </Route>
      <Route exact path="/:chainName/supertoken/:address">
        <MainLayout>
          <SuperTokenPage />
        </MainLayout>
      </Route>
      <Route exact path="/:chainName/account/:address">
        <MainLayout>
          <AccountPage />
        </MainLayout>
      </Route>
      <Route exact path={ADDRESSES_PATH}>
        <MainLayout>
          <AddressesPage />
        </MainLayout>
      </Route>
      <Route exact path="/history">
        <MainLayout>
          <FavoritesPage />
        </MainLayout>
      </Route>
      <Route exact path="/subgraph" component={SubgraphPage} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
