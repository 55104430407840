import React from 'react';
import styled from 'styled-components';

export const LoadMoreLink = styled.button`
  background: none !important;
  border: none;
  padding: 0 0.1rem;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  &&:disabled {
    color: #cacaca;
    cursor: auto;
  }
`;

const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

type Props = {
  disabled: boolean;
  handleLoadMore: () => void;
};

export const LoadMore: React.FC<Props> = ({ disabled, handleLoadMore }) => {
  return (
    <LoadMoreContainer>
      <LoadMoreLink
        data-cy={'load-more-button'}
        disabled={disabled}
        onClick={handleLoadMore}
      >
        Load More
      </LoadMoreLink>
    </LoadMoreContainer>
  );
};
