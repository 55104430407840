import React, { useCallback, useEffect, useState } from 'react';
import { Tr, Td, Bold } from '../components/CurrencyTable';
import SubscriberRow from '../components/SubscriberRow';
import { LoadMore } from '../components/LoadMore';
import SuperLoader from '../components/SuperLoader';

import { ChainType } from '../hooks/useAddressSearch';
import { getLastElement } from '../lib/addressHelpers';
import { getNetworkByName } from '../lib/networks';
import { IDA_PAGE_LENGTH } from '../constants';

import { fetchIndexSubscriptions, IndexSubscription } from '../graphql';

type Props = {
  chainName: string;
  address: string;
  approved: boolean;
};

const IndexSubscriptionData: React.FC<Props> = ({
  chainName,
  address,
  approved,
}) => {
  const [lastId, setLastId] = useState('');
  const [page, setPage] = useState(1);
  const [subscriptions, setSubscriptions] = useState<IndexSubscription[]>([]);
  const [newSubscriptionCount, setNewSubscriptionCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchIndexSubscriptionsForAccount = useCallback(
    async (gt = true) => {
      const pageSize = IDA_PAGE_LENGTH;
      const network = getNetworkByName(chainName);
      if (network) {
        setLoading(true);
        const newSubscriptions = await fetchIndexSubscriptions(
          address,
          network.id,
          lastId,
          gt,
          pageSize,
          approved
        );
        setLoading(false);
        if (newSubscriptions.length <= 0) {
          setNewSubscriptionCount(0);
          return;
        }
        setSubscriptions([...subscriptions, ...newSubscriptions]);
        setNewSubscriptionCount(newSubscriptions.length);
        const el = getLastElement(newSubscriptions);

        if (el) {
          setLastId(el.id);
        }
      }
    },
    [address, chainName, lastId, approved, subscriptions]
  );

  const handlePageForward = () => {
    fetchIndexSubscriptionsForAccount();
    setPage(page + 1);
  };

  useEffect(() => {
    fetchIndexSubscriptionsForAccount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Td>
        <Bold>Publisher</Bold>
      </Td>
      <Td>
        <Bold>Token</Bold>
      </Td>
      <Td>
        <Bold>Total Index Units</Bold>
      </Td>
      <Td>
        <Bold>Total Amount Received</Bold>
      </Td>
      <Td>
        <Bold>Time Period</Bold>
      </Td>
      <Td></Td>
      <Td>
        <Bold>Subscription Units</Bold>
      </Td>
      {subscriptions.map((subscription) => (
        <SubscriberRow
          key={subscription.id}
          subscription={subscription}
          chainName={chainName as ChainType}
        />
      ))}
      {loading ? (
        <tr>
          <td colSpan={7}>
            <SuperLoader />
          </td>
        </tr>
      ) : (
        <></>
      )}
      <Tr key="row">
        <Td colSpan={7}>
          <LoadMore
            disabled={newSubscriptionCount < IDA_PAGE_LENGTH}
            handleLoadMore={handlePageForward}
          />
        </Td>
      </Tr>
    </>
  );
};

export default IndexSubscriptionData;
