import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import AddressSearch from '../components/AddressSearch';
import EditName from '../components/EditName';
import StreamTable from '../components/StreamTable';
import IndexSubscriptionData from '../components/IndexSubscriptionData';
import { PublisherTable } from '../components/Publisher';
import StreamGranularity from '../components/StreamGranularity';

import {
  Table,
  DataRow,
  IdaRow,
  PublisherRow,
  Td,
  Bold,
  IdaSubscribedRow,
  IdaUnsubscribedRow,
} from '../components/CurrencyTable';
import {
  storeAddress,
  removeAddress,
  getAddress,
  buildAddressKey,
} from '../lib/localStorage';
import { getShortenAddress, getAddressName } from '../lib/addressHelpers';
import { EntityType, ChainType } from '../hooks/useAddressSearch';
import {
  fetchAccount,
  fetchIndexSubscriptions,
  IndexSubscription,
  AccountWithToken,
} from '../graphql';
import { getNetworkByName, getProviderByChainName } from '../lib/networks';
import { IDA_PAGE_LENGTH } from '../constants';

import '@reach/listbox/styles.css';
import Star from '../components/Star';
import Metadata from '../components/Metadata';
import _ from 'lodash';

type AccountParams = {
  address: string;
  chainName: string;
};

const GridBody = styled.div`
  grid-area: body;
  display: grid;
  grid-template:
    'title search' 6rem
    'currency currency' 1fr
    / 1fr 1fr;
`;

const PageTitle = styled.h1`
  grid-area: title;
  margin-left: 2rem;
`;

const TableContainer = styled.div`
  grid-area: currency;
  margin-left: 2rem;
  margin-bottom: 2rem;
`;

const TableCurrency = styled(Table)`
  width: 100%;
`;
const GridAddressSearch = styled(AddressSearch)`
  grid-area: search;
  justify-self: end;
  justify-content: flex-end;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const NameContainer = styled.div`
  display: flex;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const AccountPage: React.FC = () => {
  const { address, chainName } = useParams<AccountParams>();
  const network = useMemo(() => getNetworkByName(chainName), [chainName]);
  const [exists, setExists] = useState(false);
  const [blocktime, setBlocktime] = useState(0);
  const [tokens, setTokens] = useState<AccountWithToken[]>([]);
  const [approvedSubscriptions, setApprovedSubscriptions] = useState<
    IndexSubscription[]
  >([]);
  const [unapprovedSubscriptions, setUnapprovedSubscriptions] = useState<
    IndexSubscription[]
  >([]);

  const fetchIndexSubscriptionsForAccount = useCallback(
    async (gt = true) => {
      const pageSize = IDA_PAGE_LENGTH;
      if (!network) {
        return;
      }
      const approvedIndexes = await fetchIndexSubscriptions(
        address,
        network.id,
        '',
        gt,
        pageSize,
        true
      );
      setApprovedSubscriptions(approvedIndexes);
      const unapprovedIndexes = await fetchIndexSubscriptions(
        address,
        network.id,
        '',
        gt,
        pageSize,
        false
      );
      setUnapprovedSubscriptions(unapprovedIndexes);
    },
    [address, network]
  );

  const handleFavoriteClick = () => {
    if (exists) {
      removeAddress(address, chainName as ChainType);
    } else {
      storeAddress({
        name: getShortenAddress(address),
        address,
        type: 'Account' as EntityType,
        chain: chainName as ChainType,
        id: address,
      });
    }
    setExists(!exists);
  };

  useEffect(() => {
    const fetchTokens = async () => {
      if (!network) {
        return;
      }
      const account = await fetchAccount(address, network.id);
      if (account) {
        const userTokens = account.accountWithToken;
        if (userTokens) {
          setTokens(userTokens);
        }
      }
    };
    fetchTokens();
  }, [address, network]);

  useEffect(() => {
    const exists = getAddress(buildAddressKey(address, chainName as ChainType));
    if (exists) {
      setExists(true);
    } else {
      setExists(false);
    }
  }, [address, chainName]);

  useEffect(() => {
    const getBlocktime = async () => {
      if (!network) {
        return;
      }
      const name = network.name.toLowerCase();
      const provider = await getProviderByChainName(name);
      const blockNumber = await provider.getBlockNumber();
      const block = await provider.getBlock(blockNumber);
      setBlocktime(block?.timestamp);
    };
    getBlocktime();
  }, [network]);

  useEffect(() => {
    fetchIndexSubscriptionsForAccount();
  }, [fetchIndexSubscriptionsForAccount]);

  return (
    <>
      {!network ? (
        <GridBody>
          <PageTitle data-cy={'invalid-network-error'}>
            Network not found.
          </PageTitle>
        </GridBody>
      ) : (
        <GridBody>
          <PageTitle>
            <NameContainer>
              <span
                onClick={handleFavoriteClick}
                style={{ marginRight: '1rem' }}
              >
                <Star filled={exists} />
              </span>
              <NoWrap>Account for{` `}</NoWrap>
              <EditContainer>
                <EditName
                  address={{
                    name: getAddressName(address, chainName as ChainType),
                    chain: chainName as ChainType,
                    address: address,
                    type: 'Account' as EntityType,
                    id: address,
                  }}
                />
              </EditContainer>
            </NameContainer>
          </PageTitle>
          <GridAddressSearch />
          <TableContainer>
            <Metadata header="Network" metadata={_.capitalize(chainName)} />
            <StreamGranularity />
            <TableCurrency>
              <tbody>
                {unapprovedSubscriptions.length +
                approvedSubscriptions.length ? (
                  <IdaRow>
                    {approvedSubscriptions.length ? (
                      <IdaSubscribedRow>
                        <IndexSubscriptionData
                          address={address}
                          chainName={chainName}
                          approved={true}
                        />
                      </IdaSubscribedRow>
                    ) : (
                      <></>
                    )}
                    {unapprovedSubscriptions.length ? (
                      <IdaUnsubscribedRow>
                        <IndexSubscriptionData
                          address={address}
                          chainName={chainName}
                          approved={false}
                        />
                      </IdaUnsubscribedRow>
                    ) : (
                      <></>
                    )}
                  </IdaRow>
                ) : (
                  <></>
                )}
                <PublisherRow>
                  <PublisherTable address={address} chainId={network.id} />
                </PublisherRow>
                {tokens.map((accountWithToken) => (
                  <DataRow
                    name={accountWithToken.token.name}
                    key={accountWithToken.token.id}
                    chainId={network.id}
                    tokenId={accountWithToken.token.id}
                    accountAddress={address}
                    tokenSymbol={accountWithToken.token.symbol}
                  >
                    <tr>
                      <Td></Td>
                      <Td>
                        <Bold>Flow Rate</Bold>
                      </Td>
                      <Td>
                        <Bold>Streamed Amount</Bold>
                      </Td>
                      <Td>
                        <Bold>Time Active</Bold>
                      </Td>
                    </tr>
                    <StreamTable
                      address={address}
                      chainId={network.id}
                      tokenAddress={accountWithToken.token.id}
                      blocktime={blocktime}
                    />
                  </DataRow>
                ))}
              </tbody>
            </TableCurrency>
          </TableContainer>
        </GridBody>
      )}
    </>
  );
};

export default AccountPage;
